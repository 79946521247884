@tailwind base;
@tailwind components;
@tailwind utilities;

/* Regular variant */
@font-face {
    font-family: 'LogoBlack';
    src: url('./assets/fonts/FilsonProBlack.otf') format('opentype');
}

/* Medium variant */
@font-face {
    font-family: 'LogoMedium';
    src: url('./assets//fonts/FilsonProMedium.otf') format('opentype');
}

/* Apply the regular variant to elements */
.logo-black {
    font-family: 'LogoBlack', sans-serif;
}

/* Apply the medium variant to elements */
.logo-medium {
    font-family: 'LogoMedium', sans-serif;
}
