.home-body {
    height: 100vh;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
}

.fixed-bg {
    height: 100vh;
    position: fixed;
    width: 100vw;
    /* background-color: #013e97; */
    background-color: theme('colors.primary');
}

.grain-texture {
    position: fixed;
    height: 100vh;
    width: 100vw;
    opacity: 0.3;
    background: url('../grain-texture.png');
}

.bg-circle {
    position: fixed;
    width: 1024px;
    height: 1024px;
    right: -300px;
    top: -644px;
    background: url('../bg-circle.png');
    opacity: 0.075;
}

.dark-gradient {
    position: fixed;
    width: 762px;
    height: 792px;
    right: -650px;
    top: -102px;
    background: #013a8c;
    opacity: 0.9;
    box-shadow: 0px 4px 4px 130px #000000;
    filter: blur(332px);
}

.globe-vector {
    position: fixed;
    background-repeat: no-repeat;
    height: 1200px;
    width: 700px;
    left: 0px;
    bottom: -1040px;
    background: url('../globe-vector.png');
    opacity: 0.15;
}

.yellow-gradient {
    position: fixed;
    width: 1500px;
    height: 1500px;
    left: -864px;
    top: 650px;
    background: #ce9d2c;
    opacity: 0.9;
    box-shadow: 0px 4px 4px 130px #000000;
    filter: blur(332px);
}

.home-tabs {
    background-color: rgba(0, 0, 0, 0.1);
}

.home-tabs .ant-tabs-content {
    background-color: transparent;
}

.home-tabs .ant-tabs-tabpane {
    background-color: transparent;
}

.home-tabs .ant-tabs-nav::before {
    border-bottom: none;
}

.home-tabs .ant-tabs-tab {
    color: white;
    font-weight: 600;
}

.home-tabs .ant-tabs-tab-active {
    color: white;
}

.swiper-main {
    position: relative;
    width: '100%';
}

.swiper-pagination {
    margin-top: 24px !important;
    position: relative !important;
}

.swiper-pagination .swiper-pagination-bullet {
    background-color: white;
    width: 12px;
}

.swiper-button {
    position: absolute;
    top: 30%;
    z-index: 10;
    cursor: pointer;
    color: white;
}
.swiper-button svg {
    width: 25px;
    height: 25px;
}
.image-swiper-button-prev {
    left: 8px;
}
.image-swiper-button-next {
    right: 8px;
}
